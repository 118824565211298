import { AfterViewInit, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { fabric } from 'fabric';
import { ElementRef, ViewChild, HostListener } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { MiscService } from 'src/app/services/misc.service';

import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatDialog } from '@angular/material/dialog';
import { QuestionDialogComponent } from '../../dialogs/questionDialog/questionDialog.component';

import { ToastrService } from 'ngx-toastr';
import { StateService } from 'src/app/services/state.service';

@Component({
  selector: 'app-full-image-preview',
  templateUrl: './fullscreen-image-dialog.component.html',
  styleUrls: ['./fullscreen-image-dialog.component.scss'],
})
export class FullScreenImageDialogComponent implements OnInit, AfterViewInit {
  @ViewChild('canvas') canvasElement!: ElementRef;
  imageUrl: string;
  canvas: any;
  sliderValue: number = 5;
  img: any;
  undoStack: any[] = [];
  colorPicker: any;
  constructor(
    public dialogRef: MatDialogRef<FullScreenImageDialogComponent>,
    private miscService: MiscService,
    private userService: UserService,
    private stateService: StateService,
    private toast: ToastrService,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.imageUrl = this.data.imageUrl;
    this.colorPicker = document.getElementById('colorPicker');
    this.colorPicker.addEventListener('input', (e) => {
      this.canvas.freeDrawingBrush.color = (e.target as any).value;
    });
  }
  ngAfterViewInit(): void {
    if (!this.img) {
      this.canvas = new fabric.Canvas('image-canvas', {
        backgroundColor: '#f0f0f0',
      });

      fabric.Image.fromURL(
        this.imageUrl,
        (img: fabric.Image) => {
          // set the canvas width and height to match the image dimensions
          this.canvas.setDimensions({ width: img.width, height: img.height });

          // add the image to the canvas
          this.canvas.add(img);
          this.img = img;
        },
        { crossOrigin: 'Anonymous' },
      );

      this.canvas.isDrawingMode = true;
      this.canvas.freeDrawingBrush.width = 5;
      this.canvas.freeDrawingBrush.color = 'black';
    }
    this.trackChanges();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'z' && (event.ctrlKey || event.metaKey)) {
      this.undoLastAction();
    }
  }

  trackChanges() {
    this.canvas.on('path:created', (e: any) => {
      const path = e.path;
      this.undoStack.push(path);
    });
  }

  undoLastAction() {
    if (this.undoStack.length > 0) {
      const lastPath = this.undoStack.pop();
      this.canvas.remove(lastPath);
    }
  }

  changeSliderValue(event: any) {
    this.canvas.freeDrawingBrush.width = event;
  }

  formatLabel(value: number): string {
    return `${value}px`;
  }

  async saveImage() {
    this.stateService.toggleWaitingPage(true);
    const blob = this.miscService.dataURItoBlob(
      this.canvas.toDataURL({ format: 'png' }),
    );
    const file = new File([blob], 'image.png', { type: 'image/png' });
    const compressedFile = await this.compress2(file);
    this.miscService.replaceMedia([compressedFile], this.data.image).subscribe({
      next: (res) => {
        this.stateService.toggleWaitingPage(false);
        this.toast.success('Image saved successfully');
        this.dialogRef.close(res);
      },
      error: (err) => {
        this.stateService.toggleWaitingPage(false);
        this.toast.error('Error saving image');
      },
    });
  }

  compress2(file: File): Promise<any> {
    const width = 600;
    const reader = new FileReader();
    reader.readAsDataURL(file);
    return new Promise((resolve, reject) => {
      reader.onload = (ev) => {
        const img = new Image();
        img.src = (ev.target as any).result;
        (img.onload = () => {
          const elem = document.createElement('canvas');
          const scaleFactor = width / img.width;
          elem.width = width;
          elem.height = img.height * scaleFactor;
          const ctx = <CanvasRenderingContext2D>elem.getContext('2d');
          ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
          ctx.canvas.toBlob(
            (blob) => {
              resolve(
                new File([blob], file.name, {
                  type: 'image/jpeg',
                  lastModified: Date.now(),
                }),
              );
            },
            'image/jpeg',
            0.99,
          );
        }),
          (reader.onerror = (error) => reject(error));
      };
    });
  }

  downloadImage() {
    const dataUrl = this.canvas.toDataURL({ format: 'png' });
    const a = document.createElement('a');
    a.href = dataUrl;
    a.download = 'image.png';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  deleteImage() {
    const data = {
      title: 'Delete Image',
      description: 'Are you sure you want to delete this image?',
      showCheckBox: true,
      checkBoxQuestion: 'Notify user via email?',
    };
    const dialog = this.dialog.open(QuestionDialogComponent, {
      width: '500px',
      data: data,
    });

    dialog.afterClosed().subscribe((res) => {
      if (res.res === 'yes') {
        this.stateService.toggleWaitingPage(true);
        const body = {
          id: this.data.image.id,
          resource: this.data.image.resource,
          resourceId: this.data.image.resourceId,
        };

        this.userService
          .deleteUserMediaImage(body, res.checkBoxStatus)
          .subscribe({
            next: () => {
              this.stateService.toggleWaitingPage(false);
              this.toast.success('Image deleted');
              this.dialogRef.close('deleted');
            },
            error: (err) => {
              this.stateService.toggleWaitingPage(false);
              this.toast.error('Error deleting image');
            },
          });
      }
    });
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
